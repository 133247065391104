@import "./normalize.css";
@import "./reset.css";
@import "./fonts.css";


*, * {
  box-sizing: border-box;
}

.swiper-slide {
  width: 100px !important;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: 'Futura-PT', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: rgb(240, 241, 242);*/
  height: 100%;
  background: #fff;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.photodrop-futura {
  font-family: 'Futura-PT', serif;

}



.reactEasyCrop_CropArea {
  box-shadow: 0 0 0 9999em;
  color: #262626 !important;
}

.reactCodeInput {
  margin: 19px 0 0 !important;
  display: flex !important;
  justify-content: space-between !important;
}

